class Position {
    constructor(left = 0, top = 0) {
        this.left = left;
        this.top = top;
    }
}
export class Preloader {
    constructor() {
        this.elementQueue = 0;
        this.totalElements = 0;
        this.complete = false;
        this.onComplete = () => { };
        this.onProgress = (progress) => { };
    }
    getPosition(e) {
        let pos = new Position();
        do {
            pos.left += e.offsetLeft;
            pos.top += e.offsetTop;
            e = e.offsetParent;
        } while (e != null);
        return pos;
    }
    run() {
        let st = window.scrollY;
        let sh = st + window.innerHeight;
        let elements = document.querySelectorAll("img");
        this.elementQueue = elements.length;
        elements.forEach((e) => {
            if (e.complete) {
                --this.elementQueue;
                return;
            }
            if (e.offsetParent == null) {
                --this.elementQueue;
                return;
            }
            let pos = this.getPosition(e);
            if (pos.top < st || pos.top > sh) {
                --this.elementQueue;
                return;
            }
            e.onload = () => this.loadHandler();
            e.onerror = () => this.loadHandler();
            e.onabort = () => this.loadHandler();
        });
        this.totalElements = this.elementQueue;
        if (this.elementQueue == 0) {
            this.complete = true;
            this.onComplete();
        }
        window.setTimeout(() => this.timeout(), 5000);
    }
    timeout() {
        if (this.complete)
            return;
        this.complete = true;
        this.onComplete();
    }
    loadHandler() {
        --this.elementQueue;
        if (this.elementQueue <= 0 && !this.complete) {
            this.complete = true;
            this.onComplete();
        }
        else {
            this.onProgress((this.totalElements - this.elementQueue) / this.totalElements);
        }
    }
}
